import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Waypoint } from "react-waypoint"

//Importing images
import movement from "../../../images/home/section-two/movement.png"
import comfy from "../../../images/home/section-two/comfy.png"
import valuta from "../../../images/home/section-two/valuta.png"

const SectionTwo = () => {
  const [show, setShow] = useState(false)

  return (
    <Container>
      <Waypoint
        onEnter={() => setShow(true)}
        onLeave={() => setShow(false)}
        topOffset={"-10%"}
        bottomOffset={"-20%"}
      />
      <TopContainer
        style={{
          opacity: show ? 1 : 0,
        }}
      >
        <Movement></Movement>
        <ParagraphLeft>
          Mere end bare en app - en movement der vil skabe bedre fester.
          Corona-guides til fester, danske musikere osv. osv.
        </ParagraphLeft>
        <StyledLink to="/home">
          <Button>
            Følg med{" "}
            <span role="img" aria-label="pointing-right">
              👉
            </span>
          </Button>
        </StyledLink>
      </TopContainer>

      <FlexGridContainer>
        <UpperFlexGrid>
          <UpperLeftContainer>
            <UpperLeft />
          </UpperLeftContainer>
          <UpperRightContainer>
            <UpperRight />
          </UpperRightContainer>
        </UpperFlexGrid>
        <LowerFlexGrid>
          <LowerLeftContainer>
            <LowerLeft />
          </LowerLeftContainer>
          <LowerRightContainer>
            <LowerRight />
          </LowerRightContainer>
        </LowerFlexGrid>
      </FlexGridContainer>
    </Container>
  )
}

export default SectionTwo

/*<OverlayDivLong>
              <H1>Detaljen</H1>
              <Paragraph>
                I en corona-tid uden fester har vi lavet en mini-serie på
                instagram. <br />
                <br />
                Her promoverer ...
              </Paragraph>
            </OverlayDivLong>
            */

const Container = styled.section`
  width: 100%;
  height: auto;

  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 1612px) {
  }
`
const Image = styled.img`
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;

  margin: 0;
`
const TopContainer = styled.div`
  width: 80%;
  height: 20%;
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 5%;

  transition: 0.5s ease;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    margin-top: 5%;
  }
`
const Movement = styled(Image)`
  content: url(${movement});
  /*transform: translateX(${({ animate }) => (animate ? "0" : "-100vw")});
  transition: transform 1s;*/
  max-width: 30%;
  width: auto;
  height: auto;
`
const ParagraphLeft = styled.p`
  font-weight: normal;
  color: #333;
  font-size: 16px;
  text-align: left;

  margin: 40px 0;

  @media screen and (min-width: 480px) {
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`
const Button = styled.div`
  margin: 0;
  width: 120px;
  height: 33px;
  font-size: 16px;
  font-weight: bold;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 0px 20px 5px rgba(234, 200, 68, 0.5);
  -moz-box-shadow: 0px 0px 20px 5px rgba(234, 200, 68, 0.5);
  box-shadow: 0px 0px 20px 5px rgba(234, 200, 68, 0.5);

  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 480px) {
    width: 170px;
    height: 48px;
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    width: 220px;
    height: 66px;
    font-size: 30px;
  }
`
const StyledLink = styled(props => <Link {...props} />)`
  font-size: 16px;
  color: #333;

  @media screen and (min-width: 480px) {
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    font-size: 30px;
  }
`
const FlexGridContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 2vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    margin-top: 5vh;
    margin-bottom: 3vh;
  }
`
const UpperFlexGrid = styled.div`
  position: relative;
  margin-bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    max-width: 80%;
    width: 50%;
    height: auto;
    margin-bottom: -3%;

    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`
const LowerFlexGrid = styled.div`
  position: relative;
  margin-bottom: 0;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    max-width: 80%;
    width: 50%;
    height: auto;

    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  padding: 7% 0;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`
const LongCard = styled.img`
  width: auto;
  height: 300px;

  margin: 0;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    height: 500px;
    max-height: 500px;
  }

  -webkit-box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.34);
  -moz-box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.34);
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.34);

  -webkit-box-shadow: 6px 6px 20px 0px rgba(51, 51, 51, 0.6);
  -moz-box-shadow: 6px 6px 20px 0px rgba(51, 51, 51, 0.6);
  box-shadow: 6px 6px 20px 0px rgba(51, 51, 51, 0.6);
`
const WideCard = styled.img`
  width: 80%;
  height: auto;
  margin: 0;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    max-width: 400px;
    width: 100%;
  }

  -webkit-box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.34);
  -moz-box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.34);
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.34);

  -webkit-box-shadow: 6px 6px 20px 0px rgba(51, 51, 51, 0.6);
  -moz-box-shadow: 6px 6px 20px 0px rgba(51, 51, 51, 0.6);
  box-shadow: 6px 6px 20px 0px rgba(51, 51, 51, 0.6);
`
const UpperLeftContainer = styled(CardContainer)`
  width: 100%;
  height: 50%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;
    max-height: 500px;
    justify-content: center;
  }
`
const UpperRightContainer = styled(CardContainer)`
  width: 100%;
  height: 50%;
  max-width: 300px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 768px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    max-width: 400px;
    height: 100%;
    justify-self: center;
    justify-content: center;
  }
`
const LowerLeftContainer = styled(CardContainer)`
  width: 100%;
  height: 50%;
  max-width: 300px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 768px) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    max-width: 400px;
    height: 100%;
    justify-content: center;
    justify-self: center;
  }
`
const LowerRightContainer = styled(CardContainer)`
  width: 100%;
  height: 50%;
  max-height: 420px;

  display: flex;
  justify-content: flex-start;

  @media screen and (min-width: 480px) {
  }

  @media screen and (min-width: 768px) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    max-height: 500px;
    height: 100%;
    justify-content: center;
  }
`
const UpperLeft = styled(LongCard)`
  content: url(${comfy});
`
const UpperRight = styled(WideCard)`
  content: url(${valuta});
`
const LowerLeft = styled(WideCard)`
  content: url(${valuta});
`
const LowerRight = styled(LongCard)`
  content: url(${comfy});
`
/*const OverlayDivLong = styled.div`
  height: 100%;
  width: 80%;
  background-color: #fdde68;
  margin: 0;

  padding: 20% 10%;

  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.9;
  }
`
const OverlayDivWide = styled.div`
  height: auto;
  width: 100%;
  background-color: #fdde68;
  margin: 0;

  padding: 20% 10%;

  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.9;
  }
`
const H1 = styled.h1`
  font-weight: bold;
  color: #333;
  font-size: 20px;
  text-align: center;

  @media screen and (min-width: 480px) {
    font-size: 24px;
  }

  @media screen and (min-width: 768px) {
    font-size: 28px;
  }
`
const Paragraph = styled.p`
  font-weight: normal;
  color: #333;
  font-size: 16px;
  text-align: center;

  @media screen and (min-width: 480px) {
    font-size: 20px;
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
`
*/
